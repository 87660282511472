'use client'

import { isBrowser } from "@/lib/utils/utils"
import { useEffect, useState } from "react"
import ReactPlayer from "react-player"

export function VideoPlayer({ videoUrl, playing = false, controls = true, muted = true, width='100%', height='100%' }:
  { videoUrl: string, playing?: boolean, controls?: boolean, muted?: boolean, width?: string, height?:string }) {
  
  const [hasWindow, setHasWindow] = useState(false)
  useEffect(() => setHasWindow(isBrowser()), [])

  return (
    !hasWindow || !videoUrl ? <></> :
    <div id='VIDEO_WRAPPER' className='relative h-full w-full p-0'>
      <div id='VIDEO_CONTAINER' className='relative h-full w-full overflow-hidden rounded-xl shadow-md'>
        <ReactPlayer
          url={videoUrl}
          playing={playing}
          controls={controls}
          muted={muted}
          volume={0.1}
          width={width}
          height={height}/>
      </div>
    </div>
  )
}
