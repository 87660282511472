import { SITE_IS_JBDA } from '@/config/site.config'
import { getLangCode, I18nValue } from '../i18n/lang-context'
import { getGlossaries } from '@/lib/glossary/glossary-services'

export type GlossaryEntry = {
  name: I18nValue
  shortDesc: I18nValue
  longDesc: I18nValue
}
export type Glossary = { [key: string]: GlossaryEntry }

let GLOSSARY: { [lang: string]: Glossary} = {}

async function refreshGlossary(lang: string) {
  if(SITE_IS_JBDA)
    return {}
  return await getGlossaries(lang).then((results: any) => {
    const glossary: Glossary = {}
    for(let result of results) {
      glossary[result.key] = {name: {}, shortDesc: {}, longDesc: {}}
      glossary[result.key].name[lang] = result.name
      glossary[result.key].shortDesc[lang] = result.shortDesc
      glossary[result.key].longDesc[lang] = result.longDesc
    }
    GLOSSARY[lang] = glossary
    return glossary as Glossary
  })
}
export async function getGlossary(glossary?: Glossary, lang?: string) {
  if(glossary) {
    return glossary
  }
  lang = lang || getLangCode()
  if(!GLOSSARY[lang]) {
    return await refreshGlossary(lang).then((glossary: Glossary) => glossary)
  }
  return GLOSSARY[lang]
}
export async function getGlossaryEntry(key: string, glossary: Glossary, lang?: string) {
  lang = lang || getLangCode()
  return await getGlossary(glossary, lang).then((_glossary: Glossary) => {
    const glossaryEntry = readGlossaryEntry(key, _glossary)
    if(!glossaryEntry) {
      return Promise.resolve(refreshGlossary(lang as string).then((glossary: Glossary) => readGlossaryEntry(key, glossary)))
    }
    return glossaryEntry
  })
}
export function readGlossaryEntry(key: string, glossary: Glossary) {
  return glossary && glossary[key]
}
