import request, { gql } from 'graphql-request'
import { DEFAULT_HYGRAPH_LANG, toLocalesHygraph } from '../utils/utils'

const GRAPHQL_API = 'https://eu-west-2.cdn.hygraph.com/content/cm2s1lf6e03ru07w3od1wmzsg/master'
// const GRAPHQL_API = 'https://api-eu-west-2.hygraph.com/v2/cl9toxj1t4abn01tdahgp0hla/master'
// const GRAPHQL_API: string = process.env.GRAPHQL_API || ''

export const getGlossaries = async (locale: string = DEFAULT_HYGRAPH_LANG) => {
  const query = gql`
    query GetGlossaries($locales: [Locale!]!) {
      glossariesConnection (`+ /*(SITE_IS_PROD ? `where: { production: true }, ` : ``) +*/ `locales: $locales, orderBy: name_ASC, first: 100) {
        edges {
          node {
            id
            key
            name
            shortDesc
            longDesc
          }
        }
      }
    }
  `
  return request(GRAPHQL_API, query, { locales: toLocalesHygraph(locale) }).then((result: any) => {
    return result.glossariesConnection.edges.map((edge: { node: any }) => edge.node)
  })
}